import './HNC35thAnniversary.scss';

import React from 'react';

import * as Core from 'components/core';
import Layout from 'components/layout/Base';

import Anniversary from './images/Anniversary.png';
import Diplomacy from './images/Diplomacy.gif';
import Leadership from './images/Leadership.gif';

import Heading from './Heading';

const FeatureContent: React.FC = () => {
  return (
    <Layout title="An Oasis of Academic Freedom">
      <Heading />

      <Core.ContentWrapper>
        <Core.ContentSection
          marginaliaStackedBottom
          marginalia={
            <>
              <Core.LeadIn>
                By Joan Katherine Cramer
                <br />
                <br />
                Illustration by Steve Wilson
              </Core.LeadIn>

              <img className="HNC35thAnniversary-logo" src={Anniversary} />
            </>
          }
        >
          <h1 className="HNC35thAnniversary-title">
            An Oasis
            <br />
            of Academic
            <br />
            Freedom
          </h1>

          <Core.SubHeading>
            <span className="HNC35thAnniversary-subTitle">
              Celebrating 35 Years of the
              <br />
              Hopkins-Nanjing Center
            </span>
          </Core.SubHeading>
        </Core.ContentSection>

        <Core.ContentSection>
          <Core.LargeText fontStyle="sans">
            It took a visionary leap to launch the Hopkins-Nanjing Center.
            Today, 35 years later, the center has become ever more valuable,
            bringing together students in a bilingual setting to promote a deep
            and genuine understanding.
          </Core.LargeText>

          <p>
            It was an audacious idea, born not long after Richard Nixon’s
            celebrated 1972 visit to the People’s Republic of China and the
            establishment of formal diplomatic relations in 1979. Steven Muller,
            then President of Johns Hopkins University, was visiting SAIS’s
            Bologna Center in Italy — which had been created to meet a growing
            need for international expertise in the wake of World War II — and
            thought, “Why not do this in Asia?”
          </p>
          <p>
            A remarkable feat of diplomacy, not only across cultures, but
            between two visionary university presidents and their own
            constituencies, the Hopkins-Nanjing Center (HNC) will celebrate its
            35th anniversary this fall. Not only has it endured, it has become
            ever more valuable, a testament to Muller’s vision of a rigorous
            academic program designed to address an expanding definition of what
            it means to understand the world.
          </p>
          <p>
            “He saw China as the country of the future,” says Muller’s widow,
            Jill McGovern, who has been involved with the HNC since 1981, when
            her career in educational administration brought her to the JHU
            president’s office and she helped facilitate the program’s creation.
          </p>
          <p>
            “Steve was convinced we needed to broaden the scope of what SAIS has
            to offer. International relations could not be just European
            relations,” says McGovern, who now serves on the Hopkins-Nanjing
            Council as well as the SAIS board of advisors and continues to
            support students through the McGovern-Muller Fellowship she and her
            husband created together.
          </p>
        </Core.ContentSection>

        <br />

        <Core.ContentSection
          marginalia={
            <>
              <Core.Blockquote color="black" noQuotes>
                Ideal Environs
              </Core.Blockquote>

              <Core.Caption>
                A partnership with Nanjing University seemed especially
                auspicious in 1981. Not only did the two university presidents
                see eye-to-eye, but Nanjing University was located — much like
                the University of Bologna, SAIS’s partner in Europe — in a
                charming city away from the country’s capital.
              </Core.Caption>
              <br />
              <Core.Caption>
                “It’s a green city, lush, lovely climate, small by Chinese
                standards, only 8 million people,” says John Urban, HNC’s deputy
                American co-director. “It served as the capital many times, and
                signs of its history are everywhere, from the tombs and city
                wall of the Ming Dynasty to the house where Pearl Buck wrote{' '}
                <em>The Good Earth</em>. And it is home to many universities, so
                it’s an attractive place to study and teach.”
              </Core.Caption>
              <br />
              <Core.Caption>
                As a member of the Hopkins-Nanjing Council, Jill McGovern has
                visited nearly every year, and of course she was there with her
                late husband, Johns Hopkins President Steven Muller, for the
                opening. “It’s a lovely place, set in the heart of the historic
                Nanjing University campus,” she says. “I’m smiling just thinking
                of seeing it for the first time, and then, on the 20th
                anniversary, celebrating a major expansion. It’s a special place
                and, if all goes well, I will see it again on the 35th.”
              </Core.Caption>
            </>
          }
        >
          <img
            className="HNC35thAnniversary-image"
            src={Diplomacy}
            alt="Diplomacy"
          />

          <p>
            Muller envisioned a completely cooperative venture, jointly operated
            by two universities ranked among the most prestigious in their
            countries. Chinese and American students would live together in
            small suites, Chinese students studying mostly in English,
            non-Chinese students mostly in Mandarin, in an oasis of academic
            freedom that featured China’s first uncensored open-stack library.
            The goal: close daily contact in a bilingual setting to promote a
            deep and genuine understanding.
          </p>
          <p>
            “It was undoubtedly a visionary leap to create this kind of an
            institution at the time,” says John Lipsky, the Peter G. Peterson
            Distinguished Scholar at the Henry A. Kissinger Center for Global
            Affairs at SAIS who serves as chair of the Hopkins-Nanjing Council.
            “The reforms in China that so strikingly changed the economy and the
            culture and their engagement with the rest of the world were just
            getting underway, and it wasn’t immediately obvious how important
            each country was going to become to the other.”
          </p>
          <p>
            There was a lot of skepticism in the beginning, and the project
            would likely never have gotten off the ground without Johns Hopkins
            physics professor (now emeritus){' '}
            <Core.BoldText>Chih-Yung Chien</Core.BoldText>, a naturalized U.S.
            citizen, born in Sichuan Province and raised in Taiwan, who
            introduced Muller to then Nanjing University President Kuang Yaming.
            Because he understood both cultures, Chien was key to drafting the
            original agreement. He knew, for instance, that certain words, like{' '}
            <em>leaders</em> and <em>sovereignty</em>, meant different things
            politically to the Americans and Chinese. He helped avert
            misunderstandings, studied how Chinese universities were run, and
            helped navigate the bureaucracy to get things done.
          </p>
          <p>
            “The agreement was signed in 1981, but it took five years to launch
            the program,” says McGovern. “We had to recruit faculty and students
            who had the language skills, which has always been a challenge.
            There were no cellphones, no internet, so most of the negotiations
            were by Telex and postal delivery, and Steve had to get everyone to
            agree to two important ideas. One was the construction of dorms with
            small suites, each with its own bathroom, which was unheard of in
            that era, but he wanted Chinese and American students to live
            together. The other was the open-stack library. Two or three years
            into negotiations the Chinese said, ‘No open-stack library,’ but
            Steve said that was a deal-breaker and, ultimately, he prevailed.”
          </p>
          <p>
            Over the years, the HNC has survived its share of crises. In 1988
            and 1989 there were anti-African riots in Nanjing. In 1989 there was
            Tiananmen Square. In 1999 HNC students hosted a forum to ease
            tensions over the NATO bombing of the Chinese embassy in Belgrade.
            And in 2003 the program relocated to the East-West Center in
            Honolulu for a semester to escape SARS.
          </p>
        </Core.ContentSection>

        <br />

        <Core.ContentSection
          marginalia={
            <>
              <img
                className="HNC35thAnniversary-image HNC35thAnniversary-mobile"
                src={Leadership}
                alt="Leadership"
              />

              <Core.Blockquote color="black" noQuotes>
                Expanding Impact
              </Core.Blockquote>

              <Core.Caption>
                Since the Hopkins-Nanjing Center opened its doors in 1986, the
                student body has roughly tripled in size, and the HNC’s more
                than 3,000 alumni work all over the world in diplomacy,
                journalism, academia, business, government, and for
                non-governmental organizations.
              </Core.Caption>
              <br />
              <Core.Caption>
                In 1986, there were 63 students, 24 of them American and 39
                Chinese. In 2020–21, there were 186 students, including 67
                Americans, 108 Chinese, and 11 from eight other countries:
                Australia, Canada, India, Japan, Myanmar, Singapore, South
                Korea, and the United Kingdom.
              </Core.Caption>
              <br />
              <Core.Caption>
                In 1986, the HNC offered only a one-year certificate program in
                Chinese and American Studies. Today it also offers students the
                chance to spend two years earning a Master of Arts in
                International Studies (MAIS) on the Nanjing campus. Both the
                certificate and the master’s degree are awarded jointly by Johns
                Hopkins and Nanjing universities. In 2020–21 there were 83
                students working toward their MAIS. (Students can also apply the
                certificate toward master’s degree programs on the SAIS D.C.
                and/or SAIS Europe campuses.)
              </Core.Caption>
            </>
          }
        >
          <img
            className="HNC35thAnniversary-image HNC35thAnniversary-desktop"
            src={Leadership}
            alt="Leadership"
          />

          <p>
            “But in terms of duration, COVID-19 is the biggest crisis we’ve
            faced,” says Adam Webb, HNC’s American co-director. “A year into the
            pandemic it still isn’t easy for non-Chinese students to get into
            China. Still, our virtual delivery of course material turned out
            much better than anyone expected. And last fall some of us relocated
            to SAIS Europe in Bologna where we were able to hold classes in
            person.”
          </p>
          <p>
            The pandemic has, in fact, strengthened ties between the HNC and the
            larger Hopkins and Nanjing University communities, says Webb, a
            professor of political science at HNC since 2008. Appointed
            co-director in 2019, Webb sees great potential in the center’s
            ability “to link the English-speaking and Chinese-speaking academic
            universes. We’d like to see more integration of our faculty research
            and we’d like to make other parts of Nanjing and Hopkins more aware
            of what our students and faculty are doing. The way people have
            embraced technology during the pandemic has broken down a lot of the
            psychological barriers of distance, made us more aware of one
            another, and generated a lot of ideas of things we can keep doing
            together.”
          </p>
          <p>
            The HNC curriculum continues to evolve, incorporating, for instance,
            popular courses in energy, resources, and the environment. And Webb
            is encouraging a focus on understanding the Chinese experience and
            China’s role in the world — dramatically expanded since 1986 — from
            a broader global perspective.
          </p>
          <p>
            While the relationship between the Chinese and U.S. governments is
            at a low point these days, Webb says the relationship between the
            Chinese and American faculty members, administrators, and students
            at HNC is stronger, and undoubtedly more necessary, than ever.{' '}
          </p>
          <p>
            “People who come to study here see past the politics,” says Webb.
            “They want to engage with people, understand how other people live
            and think. We help our students build the skills to navigate and
            even prevent political crises because they come to understand one
            another’s challenges and perspectives.”
          </p>
        </Core.ContentSection>

        <Core.Share />
      </Core.ContentWrapper>
    </Layout>
  );
};

export default FeatureContent;
