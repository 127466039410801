import './Heading.scss';

import React from 'react';

import * as Core from 'components/core';

import Connection from './images/Connection.gif';

const Heading: React.FC = () => {
  return (
    <Core.Masthead color="light" className="HNC35thAnniversaryHeading">
      <div>
        <img src={Connection} className="HNC35thAnniversaryHeading-image" />
      </div>
      <div className="HNC35thAnniversaryHeading-wrapper">
        <Core.ContentWrapper>
          <Core.DepartmentLabel>Feature</Core.DepartmentLabel>
        </Core.ContentWrapper>
      </div>
    </Core.Masthead>
  );
};

export default Heading;
